import React from 'react'

const ProjectIntro = () => {
  return (
    <div className="container-1">
      <div className="box-1">
        <h3 className="project-title">
          Recent <br/> Projects
          <span className="size-manager" style={{visibility: "hidden"}}>
           ................................
          </span>
        </h3>
      </div>
    <div className="box-2">
    </div>
    </div>
  )
}

export default ProjectIntro